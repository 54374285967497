<template>
  <div>
    <iframe class="frame-landing" src="landing.html" />
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    login() { },
    registration() { },
  },
  mounted() {
    window.onmessage = (event) => {
      if (typeof(event.data) == 'string') {
        if (event.data == 'login') {
          this.$router.push({ name: 'Login' })
        } else if (event.data == 'registration') {
          this.$router.push({ name: 'FreeRegistration' })
        } else {
          window.open(event.data, '_self');
        }
      }

    }
  },
}
</script>

<style lang="scss">
.frame-landing {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: none;
}
</style>
